<template>
  <div class="station-null">
      <van-empty :image="require('@/assets/station_null.svg')"  />
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
    computed: {
        ...mapState(["err_code"]),
    },
}
</script>

<style scoped lang="less">
.van-empty{
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 2.23rem;
    height: 2.7rem;
    padding: 0;
    /deep/ .van-empty__image{
        width: 100%!important;
        height: 100%!important;
        img{
            width: 100%;
            height: 100%;
        }
    }
}
</style>
